.list-container {
  max-width: 1100px;
  margin: auto;
}

.list-icon {
  margin-right: 0.5rem;
}

.list {
  font-family: 'Montserrat', sans-serif;
  font-size: 20px;
  font-weight: 500;
  list-style-type: none;
}

.list-child {
  display: flex;
  flex-direction: row;
  padding-top: 10px;
}

li {
  width: 90%;
}

.list-bullet {
  position: relative;
  top: 5px;
}