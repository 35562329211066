@import '../../../../node_modules/bootstrap/scss/bootstrap.scss';
@import url('https://fonts.googleapis.com/css2?family=Jura&family=Libre+Franklin:wght@300;400;500&family=Montserrat:ital,wght@0,200;0,400;0,500;0,600;0,700;0,800;1,400&display=swap');

.trainee-card-container {
  width: 180px;
  min-height: 200px;
  padding-top: 10px;
  display: flex;
  justify-content: flex-end;
  font-family: 'Libre Franklin', sans-serif;
  font-weight: 500;
  margin-bottom: 10px !important;
  margin: auto;
}

.trainee-card-content {
  margin-bottom: 20%;
  text-align: center;
  transition-property: margin;
  transition-duration: 0.3s;
  transition-timing-function: ease-in-out;
}

.trainee-card-image-container {
  display: flex;
  justify-content: center;
}

.trainee-image-shape-border {
  position: relative;
  z-index: 1;
  background: #20a3dc;
  width: 97px;
  height: 97px;
  border-radius: 50%;
  margin-bottom: 2%;
  transition-property: margin;
  transition-duration: 0.3s;
  transition-timing-function: ease-in-out;
}

.trainee-card-image {
  width: 90px;
  height: 90px;
  object-fit: cover;

  position: relative;
  top: 7px;
  left: 3px;
}

.trainee-card-name {
  font-size: 20px;
  color: #0f6f95;
}

.trainee-card-footer {
  padding: 0px !important;
}

.trainee-card-readMore {
  position: relative;
  max-height: 0;
  opacity: 0;
  padding-top: 0;
  padding-bottom: 0;
  background-color: #20a3dc;
  color: white;
  transition-property: max-height, opacity, padding;
  transition-duration: 0.3s;
  transition-timing-function: ease-in-out;
  width: 100%;
  border-radius: 0 0 0.375rem 0.375rem;
  display: flex;
  align-items: center;
  justify-content: center;
  &::after {
    content: 'Read More';
  }
}

.modal-subtitle {
  font-size: 20px;
  font-weight: 500;
}

.trainee-modal-img-container {
  max-width: 300px;
  display: flex;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
}

.trainee-modal-image-content {
  display: flex;
  justify-content: center;
}

.trainee-modal-image-shape-border {
  position: relative;
  z-index: 1;
  background: #20a3dc;
  width: 157px;
  height: 157px;
  border-radius: 50%;
}

.trainee-modal-image {
  width: 150px;
  height: 150px;
  object-fit: cover;
  margin-bottom: 4%;
  transition-property: margin;
  transition-duration: 0.3s;
  transition-timing-function: ease-in-out;
  position: relative;
  top: 7px;
  left: 7px;
}

.trainee-modal-text-container {
  padding-right: 30px !important;
}

.trainee-card-container:hover {
  .trainee-card-content {
    margin-bottom: 7%;
  }

  .trainee-image-shape-border {
    margin-bottom: 2%;
  }

  .trainee-card-readMore {
    opacity: 1;
    max-height: 20px;
    padding-top: 20px;
    padding-bottom: 20px;
    transition-property: max-height, opacity, padding;
    transition-duration: 0.3s;
    transition-timing-function: ease-in-out;
    cursor: pointer;
  }
}

@include media-breakpoint-down(md) {
  .trainee-card-readMore {
    opacity: 1;
    max-height: 20px !important;
    padding-top: 20px !important;
    padding-bottom: 20px !important;
    background-color: #20a3dc !important;
    color: white !important;
    &::after {
      content: 'Read More';
    }
  }

  .trainee-card-content {
    margin-bottom: 7%;
  }

  .trainee-image-shape-border {
    margin-bottom: 2%;
  }
}
