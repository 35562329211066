@import '../../../../node_modules/bootstrap/scss/bootstrap.scss';
@import url('https://fonts.googleapis.com/css2?family=Jura&family=Libre+Franklin:wght@300;400;500&family=Montserrat:ital,wght@0,200;0,400;0,500;0,600;0,700;0,800;1,400&display=swap');

.divider {
  padding: 5px;
  padding-left: 30px;
  padding-right: 30px;
}

.green-line {
  border-width: 2px;
  border-style: solid;
  border-color: #c2db5d;
  margin-bottom: 1px;
  margin-left: 10px;
  margin-top: 2px;
}

.blue-line {
  border-width: 2px;
  border-style: solid;
  border-color: #20a3dc;
  margin-top: 0px;
  margin-right: 10px;
}
