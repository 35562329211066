.spin {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #e6e6e6;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
}/*# sourceMappingURL=Spinner.css.map */