@import '../../../../node_modules/bootstrap/scss/bootstrap.scss';
@import url('https://fonts.googleapis.com/css2?family=Jura&family=Libre+Franklin:wght@300;400;500&family=Montserrat:ital,wght@0,200;0,400;0,500;0,600;0,700;0,800;1,400&display=swap');

.horizontal-scroll-container {
  width: 100%;
  overflow-x: auto;
  overflow-y: hidden;
  scrollbar-width: none;
  -ms-overflow-style: none;
  position: absolute;
  left: 0;
  right: 0;
}

.horizontal-scroll {
  display: flex;
  justify-content: space-between;
  width: 14000px;
  scrollbar-width: none;
  -ms-overflow-style: none;
}

.horizontal-scroll-container::-webkit-scrollbar {
  display: none;
}

.class-tag {
  font-family: 'Jura', sans-serif;
  font-size: 24px;
  color: #07374a;
}
