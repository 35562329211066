@import '../../../../../../../common/scss/Common.scss';

.intro-container {
  overflow-x: hidden;
  padding-top: 100px;
}

.intro-massiveImage {
  position: relative;
  width: 130vw;
  left: 35vw;
}

.intro-button-1 {
  top: 25%;
  left: 64%;
}

.intro-button-2 {
  top: 90%;
  left: 84%;
}

.intro-button-3 {
  top: 25%;
  left: 105%;
  text-wrap: nowrap;
}

.intro-button-4 {
  top: 90%;
  left: 126%;
  text-wrap: nowrap;
}

.intro-button-5 {
  top: 25%;
  left: 146%;
  text-wrap: nowrap;
}

.intro-button-6 {
  top: 90%;
  left: 168%;
  text-wrap: nowrap;
}

@include media-breakpoint-down(xxl) {
  .intro-container {
    padding-top: 200px;
  }
}

@include media-breakpoint-down(xl) {
  .intro-container {
    padding-top: 250px;
  }
}

@include media-breakpoint-down(lg) {
  .intro-container {
    transform: scale(1.15);
  }
}

@media only screen and (max-width: 768px) {
  .intro-container {
    display: none;
  }
}