.text {
  font-family: "Montserrat", sans-serif;
}

.img-container {
  display: flex;
  justify-content: center;
}

.training_img {
  -o-object-fit: cover;
     object-fit: cover;
  width: 65%;
  height: 65%;
  max-width: 1000px;
}

@media only screen and (max-width: 992px) {
  .training_img {
    width: 90%;
  }
}/*# sourceMappingURL=ResponsibilitiesSection.css.map */