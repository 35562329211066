@import '../../../../../../node_modules/bootstrap/scss/bootstrap.scss';
@import url('https://fonts.googleapis.com/css2?family=Jura&family=Libre+Franklin:wght@300;400;500&family=Montserrat:ital,wght@0,200;0,400;0,500;0,600;0,700;0,800;1,400&display=swap');

.text {
  font-family: 'Montserrat', sans-serif;
}

@include media-breakpoint-up(xl) {
  .column {
    columns: 2;
    max-width: 1000px;
    justify-content: center;
    align-items: center;
    margin: auto;
  }
}