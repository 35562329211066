@import '../../../../../../node_modules/bootstrap/scss/bootstrap.scss';
@import url('https://fonts.googleapis.com/css2?family=Jura&family=Libre+Franklin:wght@300;400;500&family=Montserrat:ital,wght@0,200;0,400;0,500;0,600;0,700;0,800;1,400&display=swap');

.ihr-container {
  margin: auto;
  font-family: 'Montserrat', sans-serif;
  padding-top: 12px;
}

.ihr-title {
  color: #b4f42a;
  font-size: 32px;
  font-weight: 800;
}

.ihr-img {
  width: 100px;
  height: 100px;
}

.ihr-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 15px;
}

.ihr-paragraph {
  width: 100%;
  font-size: 16px;
  text-align: justify;
  margin-top: 8px;
}

.ihr-quote {
  color: #4e535c;
  font-size: 12px;
  font-weight: 600;
  padding-bottom: 10px;
}

.structure-image {
  width: 80%;
  max-width: 1400px;
  display: flex;
  margin: auto;
  justify-content: center;
  align-items: center;
  margin-bottom: 5vw;
}

@media only screen and (max-width: 768px) {
  .structure-image {
    width: 95%;
  }
}

.ihr-carousel {
  height: 100%;
  width: 100%;
}

.ihr-carousel .carousel-inner {
  height: 100%;
  width: 100%;
  background-color: rgba(15, 111, 149, 0.917);
  border-radius: 24px;
}

.ihr-carousel .carousel-inner .carousel-item {
  height: 100%;
  width: 100%;
}

.ihr-carousel .carousel-caption {
  height: 100%;
}

.ihr-image-button {
  background-color: transparent !important;
  padding: 0 !important;
  border: 0px !important;
}
