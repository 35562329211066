@import '../../../../../../node_modules/bootstrap/scss/bootstrap.scss';
@import url('https://fonts.googleapis.com/css2?family=Jura&family=Libre+Franklin:wght@300;400;500&family=Montserrat:ital,wght@0,200;0,400;0,500;0,600;0,700;0,800;1,400&display=swap');

.text-container {
  width: 280px;
}

.vision-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: auto;
}

.logo-container {
  background-image: url('../../../../../assets/img/logo/logo-simple.png');
  width: 100px !important;
  height: 200px !important;
  background-repeat: no-repeat;
  background-size: contain;
}

.vision-text {
  margin-top: 10px;
  padding-left: 5px;
  border-left: 3px solid;
  border-color: #20a3dc;
  font-weight: 300;
  font-family: 'Libre Franklin', sans-serif;
  font-size: 12px;
}

.highlight {
  font-weight: 400;
  position: relative;
}

.highlight::before {
  animation: 0.7s highlightAnimation 0.7s 1 normal forwards;
  background-color: rgba(32, 163, 220, 0.5);
  content: '';
  position: absolute;
  width: 0;
  height: 60%;
  left: -1px;
  bottom: -2px;
  z-index: -1;
}

@keyframes highlightAnimation {
  to {
    width: calc(100% - 0.5px);
  }
}

@include media-breakpoint-up(sm) {
  .text-container {
    width: 360px;
  }
}

@include media-breakpoint-up(md) {
  .title-left {
    text-align: left !important;
  }

  .text-container {
    width: 440px;
  }

  .vision-container {
    flex-direction: row;
    width: 580px;
    margin: auto;
  }

  .vision-text {
    font-size: 14px;
    margin-top: 10px;
  }

  .logo-container {
    margin-right: 50px;
  }
}

@include media-breakpoint-up(lg) {
  .vision-container {
    width: 610px;
  }
}

@include media-breakpoint-up(xxl) {
  .vision-container {
    width: 800px;
  }
  .logo-container {
    width: 150px !important;
    height: 300px !important;
  }
  .text-container {
    width: 500px;
  }
  .title {
    font-size: 40px;
  }
  .vision-text {
    font-size: 15px;
  }
}
