@import '../../../../node_modules/bootstrap/scss/bootstrap.scss';
@import url('https://fonts.googleapis.com/css2?family=Jura&family=Libre+Franklin:wght@300;400;500&family=Montserrat:ital,wght@0,200;0,400;0,500;0,600;0,700;0,800;1,400&display=swap');

.peopleCard-container {
  width: 180px;
  border-radius: 0% !important;
  border: none !important;
  background-color: transparent !important;
  font-family: 'Montserrat', sans-serif;
}

.peopleCard-img {
  border-radius: 5%;
  height: 180px;
  object-fit: cover;
  transition: all 0.2s ease-in-out;
  filter: drop-shadow(0px 0px 3px #707070);
}

.peopleCard-content {
  padding: 5px 5px 10px 5px !important;
}

.peopleCard-name {
  margin-bottom: 0px !important;
  font-size: 18px;
  transition: transform 0.2s ease-in-out 0.1s;
  color: #0f6f95;
}

.peopleCard-title {
  transition: transform 0.2s ease-in-out 0.2s;
  font-size: 12px;
}

.peopleCard-container:hover {
  cursor: pointer;
}

.peopleCard-container:hover .peopleCard-img {
  filter: drop-shadow(0px 0px 7px #707070);
  transform: translateY(-5px);
}

.peopleCard-container:hover .peopleCard-name {
  transform: translateY(-5px);
}

.peopleCard-container:hover .peopleCard-title {
  transform: translateY(-5px);
}

.modal-90w {
}

.modal-header {
  border-bottom: 0px !important;
}

.modal-img-container {
  max-width: 300px;
  display: flex;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
}

.modal-img {
  width: 200px;
  height: 200px;
  object-fit: cover;
  filter: drop-shadow(0px 0px 2px #000000);
  border-radius: 10px;
  margin-bottom: 10px;
}

.modal-body {
  padding-left: 30px;
  padding-right: 30px;
  font-family: 'Montserrat', sans-serif;
  color: #07374a;
}

.modal-footer {
  border-top: 0px !important;
}

.modal-name {
  font-size: 30px;
  font-weight: 500;
}

.modal-title {
  font-size: 16px;
  font-weight: 400;
}

.modal-about {
  font-size: 20px;
  font-weight: 500;
}

.modal-about-content {
  font-size: 14px;
  font-weight: 400;
}

.people-modal-text-container {
  padding-right: 40px !important;
}

@include media-breakpoint-up(md) {
  .modal-90w {
    max-width: 90% !important;
  }
}

@include media-breakpoint-up(lg) {
  .modal-90w {
    max-width: 70% !important;
  }
}
