.fix-button-container {
  position: fixed;
  bottom: 0;
  right: 0;
  margin: 20px;
  z-index: 1000;
}

.fix-button {
  border-radius: 50px !important;
}/*# sourceMappingURL=FixButton.css.map */