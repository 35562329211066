@import '../../../../node_modules/bootstrap/scss/bootstrap.scss';
@import url('https://fonts.googleapis.com/css2?family=Jura&family=Libre+Franklin:wght@300;400;500&family=Montserrat:ital,wght@0,200;0,400;0,500;0,600;0,700;0,800;1,400&display=swap');

.hero-container {
  width: 100%;
  height: 90vh;
}

.hero-image {
  background-image: url('../../../assets/img/logo/logo.png');
  width: inherit;
  height: inherit;
  opacity: 90%;
  background-size: cover;
  background-repeat: no-repeat;
  overflow: hidden;
  text-align: center;
  font-family: 'Montserrat', sans-serif;
  background-position: 80%;
}

.hero-logo-container {
  padding: 30vh 5px 0px 5px;
  width: 280px;
  margin: auto;
}

.hero-logo {
  width: inherit;
  height: 100px;
  background-repeat: no-repeat;
  overflow: hidden;
  margin: auto;
  background-size: cover;
}

.hero-landing {
  background-image: linear-gradient(
      0deg,
      rgba(230, 230, 230, 1) 0%,
      rgba(230, 230, 230, 0.3) 100%
    ),
    url('../../../assets/img/landing-hero-image.jpg');
}

.hero-program {
  background-image: linear-gradient(
      0deg,
      rgba(230, 230, 230, 1) 0%,
      rgba(230, 230, 230, 0.3) 100%
    ),
    url('../../../assets/img/program-hero-image.jpg');
}

.hero-about {
  background-image: linear-gradient(
      0deg,
      rgba(230, 230, 230, 1) 0%,
      rgba(230, 230, 230, 0.3) 100%
    ),
    url('../../../assets/img/about-hero-image.jpg');
}

.hero-31212 {
  background-image: linear-gradient(
      0deg,
      rgba(230, 230, 230, 1) 0%,
      rgba(230, 230, 230, 0.3) 100%
    ),
    url('../../../assets/img/31212-hero-image.jpg');
}

.hero-title {
  font-weight: 500;
  color: #07374a;
  filter: drop-shadow(0px 0px 0.5px #000000);
  font-size: 32px;
  padding: 35vh 5px 0px 5px;
  font-family: 'Libre Franklin', sans-serif;
}

.landing-title {
  font-size: 22px;
  padding: 30px 5px 0px 5px;
  font-weight: 600;
  font-style: italic;
  text-transform: uppercase;
  color: #07374a;
  filter: drop-shadow(0px 2px 2px rgb(0, 0, 0, 16%));
  margin: auto;
}

.landing-quote {
  font-size: 14px;
  color: #07374a;
  padding: 10px 5px 0px 5px;
  font-weight: 400;
}

@include media-breakpoint-up(md) {
  .hero-title {
    padding-top: 40vh;
    font-size: 40px;
  }

  .landing-title {
    font-size: 35px;
    padding: 35vh 10px 0px 10px;
  }

  .landing-quote {
    font-size: 16px;
    padding: 0px 5px 0px 5px;
  }

  .hero-logo-container {
    display: none;
  }
}
