@import '../../../../../../node_modules/bootstrap/scss/bootstrap.scss';

.trainee-cards-wrapper {
  max-width: 900px;
  margin: auto;
  padding-top: 50px;
  margin-bottom: 20px;
}

.trainee-cards-container {
  justify-content: center !important;
}
