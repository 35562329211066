@import '../../../common/scss/Common.scss';

.about-parallax-layer-1 {
  margin-bottom: 100px;
}

.about-parallax-layer-2 {
  margin-bottom: -150px;
}

.about-parallax-layer-3 {
  margin-bottom: 700px;
}

.about-parallax-layer-4 {
  margin-bottom: -1150px;
}

.about-parallax-layer-5 {
  margin-bottom: -170px;
}

@include media-breakpoint-up(sm) {
  .about-parallax-layer-2 {
    margin-bottom: -130px;
  }

  .about-parallax-layer-3 {
    margin-bottom: 500px;
  }

  .about-parallax-layer-4 {
    margin-bottom: -900px;
  }

  .about-parallax-layer-5 {
    margin-bottom: -200px;
  }
}

@include media-breakpoint-up(lg) {
  .about-parallax-layer-3 {
    margin-bottom: 400px;
  }

  .about-parallax-layer-4 {
    margin-bottom: -750px;
  }
}

@include media-breakpoint-up(xl) {
  .about-parallax-layer-2 {
    margin-bottom: -100px;
  }

  .about-parallax-layer-4 {
    margin-bottom: -700px;
  }
}

@include media-breakpoint-up(xxl) {
  .about-parallax-layer-2 {
    margin-bottom: -90px;
  }

  .about-parallax-layer-4 {
    margin-bottom: -670px;
  }
}
