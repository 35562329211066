@import '../../../../node_modules/bootstrap/scss/bootstrap.scss';
@import url('https://fonts.googleapis.com/css2?family=Jura&family=Libre+Franklin:wght@300;400;500&family=Montserrat:ital,wght@0,200;0,400;0,500;0,600;0,700;0,800;1,400&display=swap');

.button-container {
  position: fixed;
  right: 30px;
  top: 30px;
  z-index: 10;
}

.dropdown-button {
  padding-bottom: 8px;
}

.menu {
  width: 250px !important;
  font-family: 'Libre Franklin', sans-serif;
  font-weight: 300;
}

.menu-button {
  text-decoration: none;
  color: #212529;
}

.menu-button-item {
  border-left: 0px !important;
  border-right: 0px !important;
  background-color: white;
  transition: background-color 0.2s;
}

.menu-button-item:active {
  background-color: #e6e6e6;
}

.accordion-header .accordion-button {
  padding: 8px;
  padding-left: 16px;
  padding-right: 16px;
  font-weight: 200;
}

.accordion-header .accordion-button:not(.collapsed) {
  background-color: #FFFFFF;
  color: #212529;
  border-radius: 0px !important;
}

.accordion-header .accordion-button::after {
  display: none;
}

.accordion-body {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}

.intern-dropdown {
  border-top: 0px !important;
  border-bottom: 0px !important;
}

.three-dropdown {
  border-bottom: 0px !important;
}