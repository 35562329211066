.partner-section-container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.partner-section-container img {
  height: 12vh;
}

@media only screen and (max-width: 768px) {
  .partner-section-container img {
    width: auto !important;
    height: auto !important;
  }
}