@import '../../../common/scss/Common.scss';

.three-container {
  overflow-x: hidden;
  padding-top: 50px;
}

.massiveImage {
  position: relative;
  width: 200vw;
}

.image-button {
  position: absolute !important;
  background-color: #20a3dc !important;
  color: white !important;
  font-family: 'Libre Franklin', sans-serif !important;
  font-weight: 500 !important;
}

@include media-breakpoint-down(xxl) {
  .three-container {
    padding-top: 200px;
  }
}

@include media-breakpoint-down(xl) {
  .three-container {
    padding-top: 250px;
  }
}

@include media-breakpoint-down(lg) {
  .three-container {
    transform: scale(1.15);
  }
}