@import '../../../../../../node_modules/bootstrap/scss/bootstrap.scss';
@import url('https://fonts.googleapis.com/css2?family=Jura&family=Libre+Franklin:wght@300;400;500&family=Montserrat:ital,wght@0,200;0,400;0,500;0,600;0,700;0,800;1,400&display=swap');

.people-result-container {
  max-width: 950px !important;
}

.people-card-container {
  display: flex;
  justify-content: center;
  padding-top: 15px;
}

.top-card-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 900px;
  margin: auto;
}

.top-image {
  width: 50%;
  object-fit: cover;
  border-radius: 7px;
  z-index: 1;
  filter: drop-shadow(0px 0px 3px #707070);
}

.top-card {
  margin-top: 0px;
  margin-bottom: 20px;
  position: relative;
  top: -40px;
}

.top-card-name {
  font-family: 'Montserrat', sans-serif;
  font-weight: 800;
  color: #07374a;
}

.top-card-subtitle {
  padding-bottom: 10px;
  font-size: 16px;
  font-family: 'Montserrat', sans-serif;
  font-weight: 500;
}

.top-card-text-container {
  padding-left: 5% !important;
  padding-top: 50px !important;
}

.top-card-about {
  font-size: 16px;
  overflow: auto !important;
  max-height: 400px;
  font-family: 'Libre Franklin', sans-serif;
}

@include media-breakpoint-up(lg) {
  .top-card-container {
    flex-direction: row;
  }

  .top-card {
    margin-top: 20px;
    border-top-left-radius: 0px !important;
    border-bottom-left-radius: 0px !important;
    top: 0;
  }

  .top-image {
    width: 40%;
    height: 100%;
  }

  .top-card-text-container {
    padding-top: 16px !important;
  }

  .top-card-about {
    max-height: 200px;
  }

  .people-card-container {
    min-width: 30%;
  }
}

@include media-breakpoint-up(xl) {
  .top-card-about {
    max-height: 300px;
  }

  .people-card-container {
    min-width: 30%;
  }
}
