.three-twelve-desktop {
  display: block;
}

.three-twelve-mobile {
  display: none;
}

@media only screen and (max-width: 768px) {
  .three-twelve-desktop {
    display: none;
  }
  .three-twelve-mobile {
    display: block;
  }
}
