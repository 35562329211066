@import '../../../common/scss/Common.scss';

.program-parallax-layer-1 {
    margin-bottom: 200px;
}

.program-parallax-layer-2 {
    margin-bottom: -150px;
}

.program-parallax-layer-3 {
    margin-bottom: 500px;
}

.program-parallax-layer-4 {
    margin-bottom: -650px;
}

.program-parallax-layer-5 {
    margin-bottom: -190px;
}

@include media-breakpoint-up(sm) {
    .program-parallax-layer-2 {
        margin-bottom: -130px;
    }

    .program-parallax-layer-3 {
        margin-bottom: 350px;
    }

    .program-parallax-layer-4 {
        margin-bottom: -550px;
    }

    .program-parallax-layer-5 {
        margin-bottom: -100px;
    }
}

@include media-breakpoint-up(xxl) {
    .program-parallax-layer-2 {
        margin-bottom: -100px;
    }
}