@import '../../../../node_modules/bootstrap/scss/bootstrap.scss';
@import url('https://fonts.googleapis.com/css2?family=Jura&family=Libre+Franklin:wght@300;400;500&family=Montserrat:ital,wght@0,200;0,400;0,500;0,600;0,700;0,800;1,400&display=swap');

.footer-container {
  position: absolute;
  bottom: 0;
  width: 100%;
  background-color: #07374a;
  z-index: 2;
}

.footer-content {
  position: relative;
  color: white;
  font-size: 2.5vw;
  padding: 10px 30px 5px 30px;
  font-family: 'Libre Franklin', sans-serif;
  font-weight: 300;
}

.footer-divider {
  border-color: white;
  margin: 10px 20px 5px 20px;
  border-style: solid;
  border-width: 2px;
}

.copyright {
  text-align: center;
  padding: 10px;
  font-size: 2vw;
}

.footer-icon {
  font-size: 3.7vw;
}

.footer-text {
  // width: 260px;
}

.footer-linkedIn {
  color: inherit;
}

.footer-linkedIn:hover {
  cursor: pointer;
  color: inherit;
}

@include media-breakpoint-up(md) {
  .footer-content {
    padding: 20px 110px 10px 110px;
    font-size: 16px;
  }

  .footer-divider {
    margin-left: 110px;
    margin-right: 110px;
  }

  .footer-icon {
    font-size: 30px;
  }

  .copyright {
    font-size: 14px;
  }

  .footer-text {
    width: 100%;
  }
}

@include media-breakpoint-up(lg) {
  .footer-content {
    padding: 20px 190px 10px 190px;
    font-size: 16px;
  }

  .footer-divider {
    margin-left: 190px;
    margin-right: 190px;
  }

  .footer-icon {
    font-size: 30px;
  }

  .copyright {
    font-size: 14px;
  }

  .footer-text {
    width: 100%;
  }
}
