@import '../../../../../../node_modules/bootstrap/scss/bootstrap.scss';
@import url('https://fonts.googleapis.com/css2?family=Jura&family=Libre+Franklin:wght@300;400;500&family=Montserrat:ital,wght@0,200;0,400;0,500;0,600;0,700;0,800;1,400&display=swap');

.intro-container {
  display: block;
  background-color: #eeeeee;
  margin-top: 80px;
  padding: 16px
}

.intro-container-content {
  display: block;
}

.intro-container-content-mobile {
  display: none;
}

@media only screen and (max-width: 768px) {
  .intro-container-content {
    display: none;
  }
  .intro-container-content-mobile {
    display: block;
  }
}