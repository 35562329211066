@import '../../../node_modules/bootstrap/scss/bootstrap.scss';
@import url('https://fonts.googleapis.com/css2?family=Jura&family=Libre+Franklin:wght@300;400;500&family=Montserrat:ital,wght@0,200;0,400;0,500;0,600;0,700;0,800;1,400&display=swap');
$color-set: (
  'background-grey': #e6e6e6,
  'logo-blue': #20a3dc,
  'logo-green': #c2db5d,
  'deep-blue': #0f6f95,
  'dark-blue': #07374a,
  'light-grey': #707070,
  'deep-grey': #4e535c,
);

body {
  overflow: overlay !important;
  background-color: map-get($color-set, 'background-grey') !important;
}

.Page-Container {
  position: relative;
  min-height: 100vh;
  padding-bottom: calc(20vh + 80px) !important;
  overflow: hidden !important;
}

.Page-Content {
  padding-left: 25px !important;
  padding-right: 25px !important;
}

.title {
  color: #20a3dc;
  font-size: 30px;
  font-family: 'Montserrat', sans-serif;
  text-align: center;
}

.White-Box {
  margin: 40px 0px 0px 0px;
  padding: 10px 0px 10px 0px;
  background-color: #fafafa;
  opacity: 90%;
  -webkit-transform: skew(-1turn, -1deg);
  transform: skew(-1turn, -1deg);
}

.White-Box-Text {
  display: block;
  -webkit-transform: skew(1turn, 1deg);
  transform: skew(1turn, 1deg);
  margin: auto !important;
}

@include media-breakpoint-up(md) {
  .Page-Content {
    padding-left: 110px !important;
    padding-right: 110px !important;
  }

  .title {
    font-size: 40px;
  }
}

@include media-breakpoint-up(lg) {
  .Page-Content {
    padding-left: 190px !important;
    padding-right: 190px !important;
  }
}
