.three-container-mobile {
  position: relative;
  display: inline-block;
}

.massiveImage-mobile {
  display: block;
  max-width: 100%;
  height: auto;
}

.image-button-mobile {
  background-color: #20a3dc;
  color: white;
  font-family: 'Libre Franklin', sans-serif;
  font-weight: 500;
  position: absolute;
  transform: translate(-50%, -50%);
  font-size: 9px;
  padding-top: 2px;
  padding-bottom: 2px;
}

.learn-more-button-1-mobile {
  top: 35%;
  left: 50%;
}

.learn-more-button-2-mobile {
  top: 47%;
  left: 50%;
}

.learn-more-button-3-mobile {
  top: 59.5%;
  left: 50%;
}

.learn-more-button-4-mobile {
  top: 72%;
  left: 49.5%;
}

.learn-more-button-5-mobile {
  top: 84.5%;
  left: 50%;
}