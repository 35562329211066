@import '../../scss/Common.scss';

.section-margin {
  margin-top: 40px;
}

.section-container {
  margin-top: 20px;
  margin-bottom: 20px;
}

.section-content-container {
  margin: 10px;
}
