.intro-button {
  background-color: #6c5cff !important;
  color: rgb(255, 255, 255) !important;
  font-family: 'Libre Franklin', sans-serif !important;
  font-weight: 500 !important;
  position: absolute !important;
  transform: translate(-50%, -50%) !important;
  font-size: 14px !important;
  padding: 4px !important;
}

.intro-cover-title {
  font-size: 64px; 
  font-weight: bold;
  text-shadow: 4px 1px 4px rgba(153, 153, 153, 0.87);
}

.intro-cover-title-sub {
  font-size: 16px;
  width: 95%;
}

@media only screen and (max-width: 768px) {
  .intro-cover-title {
    font-size: 36px; 
  }
  .intro-button {
    padding: 1px !important;
    font-size: 5px !important;
  }
}