@import '../../../../../../../common/scss/Common.scss';

.intro-container-mobile {
  position: relative;
  width: 100%;
  display: none;
}

.intro-massiveImage-mobile {
  display: block;
}

.intro-button-mobile-1 {
  top: 15%;
  left: 82%;
}

.intro-button-mobile-2 {
  top: 30%;
  left: 35%;
}

.intro-button-mobile-3 {
  top: 44%;
  left: 82%;
}

.intro-button-mobile-4 {
  top: 58%;
  left: 34%;
}

.intro-button-mobile-5 {
  top: 72%;
  left: 82%;
}

.intro-button-mobile-6 {
  top: 87%;
  left: 34%;
}

@media only screen and (max-width: 768px) {
  .intro-container-mobile {
    display: inline-block;
  }
}