@import '../../../../../../node_modules/bootstrap/scss/bootstrap.scss';
@import url('https://fonts.googleapis.com/css2?family=Jura&family=Libre+Franklin:wght@300;400;500&family=Montserrat:ital,wght@0,200;0,400;0,500;0,600;0,700;0,800;1,400&display=swap');

.cicd-container {
  margin: auto;
  font-family: 'Montserrat', sans-serif;
  padding-top: 12px;
}

.cicd-title {
  color: #0f6f95;
  font-size: 18px;
  font-weight: 800;
}

.cicd-img {
  width: 100px;
  height: 100px;
}

.cicd-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 15px;
}

.cicd-paragraph {
  width: 250px;
  font-size: 14px;
  text-align: justify;
}

.cicd-quote {
  color: #4e535c;
  font-size: 12px;
  font-weight: 600;
  padding-bottom: 10px;
}

.structure-image {
  width: 80%;
  max-width: 1400px;
  display: flex;
  margin: auto;
  justify-content: center;
  align-items: center;
  margin-bottom: 5vw;
}

@media only screen and (max-width: 768px) {
  .structure-image {
    width: 95%;
  }
}