.nav-color {
  background-color: #07374a;
}

.header-logo-container {
  position: relative;
  left: 75px;
}

.logo {
  width: 150px;
  height: 45px;
  padding-top: 0px;
  background-repeat: no-repeat;
  background-size: contain;
}

.navbar-brand {
  padding-top: 3px;
  padding-bottom: 3px;
}

.pages-container {
  position: relative;
  right: 75px;
  max-width: 350px !important;
  align-content: center;
}

.pages {
  color: white !important;
  font-size: 16px;
  font-family: 'Montserrat', sans-serif;
}

.pages-bold {
  font-weight: bold !important;
}

.header-dropdown:active {
  background-color: #0F6F95 !important;
}