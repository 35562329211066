@import '../../../common/scss/Common.scss';

.landing-parallax-layer-1 {
    margin-bottom: 20px;
}

.landing-parallax-layer-2 {
    margin-bottom: 80px;
}

.landing-parallax-layer-3 {
    margin-bottom: 50px;
}

.landing-parallax-layer-4 {
    padding-top: 100px;
    margin-bottom: 150px;
}

.landing-parallax-layer-5 {
    padding-top: 100px;
}

@include media-breakpoint-up(md) {
    .landing-parallax-layer-2 {
        margin-bottom: 170px;
    }

    .landing-parallax-layer-3 {
        margin-bottom: 100px;
    }

    .landing-parallax-layer-4 {
        margin-bottom: -170px;
    }
}

@include media-breakpoint-up(xl) {
    .landing-parallax-layer-2 {
        margin-bottom: 150px;
    }

    .landing-parallax-layer-3 {
        margin-bottom: 70px;
    }

    .landing-parallax-layer-4 {
        margin-bottom: -150px;
    }
}

@include media-breakpoint-up(xxl) {
    .landing-parallax-layer-2 {
        margin-bottom: 100px;
    }

    .landing-parallax-layer-3 {
        margin-bottom: -100px;
    }

    .landing-parallax-layer-4 {
        margin-bottom: -100px;
    }
}