@import '../../../../node_modules/bootstrap/scss/bootstrap.scss';
@import url('https://fonts.googleapis.com/css2?family=Jura&family=Libre+Franklin:wght@300;400;500&family=Montserrat:ital,wght@0,200;0,400;0,500;0,600;0,700;0,800;1,400&display=swap');

.hof-card-container {
  position: relative;
  max-width: 500px;
  margin: auto;
  margin-bottom: 30px;
  margin-top: 20px;
}

.hof-card-container-left {
  direction: ltr;
  opacity: 0;
  transform: translateX(-100%);
  transition: opacity 0.5s ease-out, transform 0.5s ease-out;
}

.hof-card-container-left.visible {
  opacity: 1;
  transform: translateX(0);
}

.hof-card-container-right {
  direction: ltr;
  opacity: 0;
  transform: translateX(100%);
  transition: opacity 0.5s ease-out, transform 0.5s ease-out;
}

.hof-card-container-right.visible {
  opacity: 1;
  transform: translateX(0);
}

.hof-image-container {
  display: flex;
  width: 100%;
  text-align: center;
}

.hof-image-container-left {
  border-top-left-radius: 12px;
  border-bottom-left-radius: 13px;
  border-top: 2px solid;
  border-left: 2px solid;
  border-color: #08a3de;
}

.hof-image-container-right {
  border-top-left-radius: 12px;
  border-bottom-left-radius: 13px;
  border-top: 2px solid;
  border-left: 2px solid;
  border-color: #08a3de;
}

.Hall-Of-Fame-Image {
  width: 100px;
  height: 90%;
  border-radius: 10px;
}

.hof-quote {
  font-family: 'Libre Franklin', sans-serif;
  font-size: 12px;
  color: #07374a;
  margin-left: 10px;
  margin-bottom: 0;
  direction: ltr;
}

.hof-name {
  font-family: 'Montserrat', sans-serif;
  font-weight: bold;
  font-size: 18px;
  color: #07374a;
  margin: 0;
}

.hof-divider {
  color: #08a3de;
  margin: 2px;
  width: 70%;
}

.hof-title {
  font-family: 'Montserrat', sans-serif;
  font-size: 10px;
  color: #07374a;
}

@include media-breakpoint-up(sm) {
  .hof-card-container-left {
    left: -5vw;
  }

  .hof-card-container-right {
    right: -5vw;
    direction: rtl;
  }

  .hof-quote {
    font-size: 14px;
  }

  .hof-image-container-right {
    border-top-right-radius: 12px;
    border-bottom-right-radius: 13px;
    border-top: 2px solid;
    border-right: 2px solid;
    border-left: none;
    border-top-left-radius: 0;
    border-color: #08a3de;
  }
}

@include media-breakpoint-up(xxl) {
  .hof-card-container {
    margin-top: 50px;
    margin-bottom: 70px;
  }

  .hof-card-container-left {
    left: -10vw;
    transform: translateX(-100%) scale(1.2);
  }

  .hof-card-container-left.visible {
    transform: translateX(0) scale(1.2);
  }

  .hof-card-container-right {
    right: -10vw;
    direction: rtl;
    transform: translateX(100%) scale(1.2);
  }

  .hof-card-container-right.visible {
    transform: translateX(0) scale(1.2);
  }
}
